import React, { FC, useEffect, useRef } from "react";

const circleColorKeyframes: Keyframe[] = [
  { backgroundColor: "#7F9AAB", offset: 0 },
  { backgroundColor: "#2CF6B3", offset: 0.5 },
  { backgroundColor: "#7F9AAB", offset: 1 },
];

const circleColorTiming: KeyframeAnimationOptions = {
  duration: 4000,
  iterations: Infinity,
};

export const Logo: FC<{ isFooter?: boolean }> = ({ isFooter }) => {
  const circleRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (circleRef.current === null) {
      return;
    }

    circleRef.current.animate(circleColorKeyframes, circleColorTiming);
  }, []);

  return (
    <>
      <span className={`nowrap ${isFooter ? "in-footer" : "out-of-footer"}`}>
        Silh
        <span
          className={isFooter ? "footer-circle" : "circle"}
          data-copy-text="o"
          ref={circleRef}
        />
        uette
      </span>
      <span>{" Punks"}</span>
    </>
  );
};
