import React, { FC } from "react";
import "./SocialMediaButtons.css";

export const SocialMediaButtons: FC<{ isFooter?: boolean }> = ({
  isFooter,
}) => {
  return (
    <>
      {isFooter && (
        <a className="social-email" href="mailto:SilhouettePunks@gmail.com">
          SilhouettePunks@gmail.com
        </a>
      )}
      <a
        className="button"
        href="https://opensea.io/collection/silhouette-punks"
      >
        Open Sea
      </a>
      <a className="button" href="https://twitter.com/silhouettepunks">
        Twitter
      </a>
      <a className="button" href="https://instagram.com/silhouettepunks">
        Instagram
      </a>
    </>
  );
};
