import "./Top.css";
import React, { useRef } from "react";
import { Logo } from "../Logo";
import { SocialMediaButtons } from "../SocialMediaButtons";
import { useCopyableLogo } from "../useCopyableLogo";

export const Top = () => {
  const headerRef = useRef<HTMLElement>(null);
  useCopyableLogo({ containerRef: headerRef });

  return (
    <>
      <header ref={headerRef} title="Silhouette Punks">
        <Logo />
      </header>
      <div className="top-social">
        <SocialMediaButtons />
      </div>
    </>
  );
};
