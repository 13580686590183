import { addOrReplaceNotification } from "../Notifications/addOrReplaceNotification";
import { NotificationType } from "../Notifications/Notifications";
import { NotificationsSetter } from "../Notifications/NotificationsSetter";
import { generateUUID } from "../utils/generateUUID";
import { Account } from "./Account";
import { askContractToMintNFT } from "./askContractToMintNFT";
import { connectWallet } from "./connectWallet";
import { ensureCorrectNet } from "./ensureCorrectNet";
import { getContractThroughEthereumProvider } from "./getContractThroughEthereumProvider";
import { getCorrectNetName } from "./getCorrectNetName";

const isPositiveInteger = (str: string) => {
  return /^([1-9]\d*)$/.test(str);
};

export const mintNFT = async ({
  currentAccount,
  setCurrentAccount,
  tokensCount: tokensCountStr,
  setNotifications,
  setIsCorrectNet,
}: {
  currentAccount: Account;
  setCurrentAccount: (acc: Account) => void;
  tokensCount: string;
  setNotifications: NotificationsSetter;
  setIsCorrectNet: (b: boolean) => void;
}): Promise<boolean> => {
  let acc: Account | undefined = currentAccount;

  const notificationID: string = generateUUID();

  if (!acc) {
    acc = await connectWallet({ setCurrentAccount });
    if (!acc) {
      addOrReplaceNotification({
        newNotification: { type: NotificationType.Error, id: notificationID },
        setNotifications,
      });
      return false;
    }
  }

  const isCorrectNet = await ensureCorrectNet({
    setIsCorrectNet,
  });
  if (!isCorrectNet) {
    addOrReplaceNotification({
      newNotification: {
        type: NotificationType.Error,
        id: notificationID,
        overrideText: `Please connect to Ethereum ${getCorrectNetName()}`,
      },
      setNotifications,
    });
    return false;
  }

  addOrReplaceNotification({
    newNotification: { type: NotificationType.MintPending, id: notificationID },
    setNotifications,
  });

  const connectedContract = await getContractThroughEthereumProvider();
  if (!connectedContract) {
    addOrReplaceNotification({
      newNotification: { type: NotificationType.Error, id: notificationID },
      setNotifications,
    });
    return false;
  }

  if (tokensCountStr.length > 0 && !isPositiveInteger(tokensCountStr)) {
    console.error("only non-negative integers are allowed");
    addOrReplaceNotification({
      newNotification: { type: NotificationType.Error, id: notificationID },
      setNotifications,
    });
    return false;
  }

  const tokensCount = tokensCountStr.length > 0 ? parseInt(tokensCountStr) : 1;

  return askContractToMintNFT({
    tokensCount,
    setNotifications,
    notificationID,
  });
};
