import "./SectionSilhouettePunks.css";

export const SectionSilhouettePunks = () => {
  return (
    <div className="section-silhouette-punks">
      <h2>Silhouette Punks</h2>
      <div>
        10,000 Silhouette Punks together create a single conceptual art piece on
        the Ethereum Blockchain.
      </div>
      <div>
        Silhouette Punks are conceptual art: They exist to promote thought and
        ask questions.
      </div>
      <div>
        About values, status, identity, creativity, communication, how we are
        different, how we are the same.
      </div>
      <div>They provide no answers. They reflect.</div>
      <div>
        This art project is not associated with the legendary Crypto Punks which
        they reference or Larva Labs.
      </div>
      <div>
        Silhouette Punks are not intended to be a vehicle for speculation. They
        may have no monetary value.
      </div>
      <div>
        They are an idea. Sold or unsold, as an artistic expression, their value
        is: They exist.
      </div>
      <div>We hope they add to the conversation.</div>
    </div>
  );
};
