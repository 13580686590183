import "./Roadmap.css";
import { FC } from "react";

const ProgressBar: FC = ({ children }) => {
  return <div className="progress-bar">{children}</div>;
};

export const Roadmap: FC = () => {
  return (
    <>
      <h2 className="roadmap">Roadmap</h2>
      <div className="roadmap-complete">Our roadmap is complete.</div>
      <div className="roadmap-item">
        <ProgressBar>50%</ProgressBar>
        <span>We exist </span>
        <img
          className="complete-icon"
          alt="complete"
          src="/icons/completeIcon.svg"
        />
      </div>
      <div className="roadmap-item">
        <ProgressBar>100%</ProgressBar>
        <span>We ask questions </span>
        <img
          className="complete-icon"
          alt="complete"
          src="/icons/completeIcon.svg"
        />
      </div>
    </>
  );
};
