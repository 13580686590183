import { MutableRefObject, useEffect, useRef } from "react";
import { ShowcaseItem } from "./ShowcaseItem";

export const rowImgCount = 24;
const imgSize = 200;
const margin = 60;

export const rowSize = rowImgCount * (imgSize + margin);

const movementXKeyframes: Keyframe[] = [
  {
    transform: "rotate(0) translate3D(-50%, -50%, 0)",
    offset: 0.0,
  },
  {
    transform: "rotate(0) translate3D(0%, -50%, 0)",
    offset: 0.5,
  },
  {
    transform: "rotate(0) translate3D(50%, -50%, 0)",
    offset: 1.0,
  },
];

const revertedMovementXKeyframes: Keyframe[] = movementXKeyframes
  .map((oldKeyframe) => {
    const keyframe = { ...oldKeyframe };
    keyframe.offset = 1.0 - (keyframe.offset || 0);
    return keyframe;
  })
  .reverse();

const movementXTiming: KeyframeAnimationOptions = {
  duration: 30000,
  iterations: Infinity,
};

export const ShowcaseRow = ({
  animationDataRef,
  reverted,
  i,
}: {
  animationDataRef: MutableRefObject<
    { animation: Animation; reverted: boolean }[]
  >;
  reverted?: boolean;
  i: number;
}) => {
  const rowRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (rowRef.current === null) {
      return;
    }

    const animation = rowRef.current.animate(
      reverted ? revertedMovementXKeyframes : movementXKeyframes,
      movementXTiming
    );
    animation.pause();
    animationDataRef.current.push({ animation, reverted: !!reverted });
  }, []);

  return (
    <div
      ref={rowRef}
      className="showcase-row"
      style={{
        width: `${rowSize}px`,
      }}
    >
      {[...Array(rowImgCount).keys()].map((_, idx) => (
        <ShowcaseItem key={`${idx}-si`} j={rowImgCount * i - idx} />
      ))}
    </div>
  );
};
