import React, { useEffect, useState } from "react";
import { Footer } from "./Footer/Footer";
import { KeyDetails } from "./KeyDetails/KeyDetails";
import { Main } from "./Main/Main";
import { Notification, Notifications } from "./Notifications/Notifications";
import { SectionRarity } from "./SectionRarity/SectionRarity";
import { Roadmap } from "./Roadmap/Roadmap";
import { Showcase } from "./Showcase/Showcase";
import { rowImgCount } from "./Showcase/ShowcaseRow";
import "./styles/App.css";
import { SectionSilhouettePunks } from "./SectionSilhouettePunks/SectionSilhouettePunks";
import { Top } from "./Top/Top";
import { preloadImages } from "./utils/preloadImages";
import { Account } from "./wallet/Account";
import { checkIfWalletIsConnected } from "./wallet/checkIfWalletIsConnected";
import { mintNFT } from "./wallet/mintNFT";

const showcaseRowsCount = 3;

function App() {
  const [currentAccount, setCurrentAccount] = useState<Account>("");
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isCorrectNet, setIsCorrectNet] = useState<boolean>(true);

  useEffect(() => {
    checkIfWalletIsConnected({
      setCurrentAccount,
      setIsCorrectNet: setIsCorrectNet,
    });
    preloadImages(
      ...[...Array(rowImgCount * showcaseRowsCount).keys()].map(
        (n) => `/sp/${n}.png`
      )
    );
  }, []);

  return (
    <>
      <div className="app">
        <Top />
        <Main
          mintNFT={({ tokensCount }: { tokensCount: string }) =>
            mintNFT({
              currentAccount,
              setCurrentAccount,
              tokensCount,
              setNotifications,
              setIsCorrectNet,
            })
          }
          warnAboutEthereumMainnet={!isCorrectNet}
        />
        <SectionSilhouettePunks />
        <SectionRarity />
        <KeyDetails />
        <Roadmap />
        <Showcase />
      </div>
      <Footer />
      <Notifications
        notifications={notifications}
        setNotifications={setNotifications}
      />
    </>
  );
}

export default App;
