import "./Main.css";
import { useRef } from "react";
import { getCorrectNetName } from "../wallet/getCorrectNetName";

export const Main = ({
  mintNFT,
  warnAboutEthereumMainnet,
}: {
  mintNFT: (arg: { tokensCount: string }) => Promise<boolean>;
  warnAboutEthereumMainnet?: boolean;
}) => {
  const tokensCountRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <div className="main-silhouette-container">
        <img className="main-silhouette" src="/main.png" />
      </div>
      <a
        className="main-oval filled"
        onClick={() => {
          return mintNFT({ tokensCount: tokensCountRef.current?.value || "" });
        }}
      >
        Mint Your Silhouette Punks Now
      </a>
      <input
        ref={tokensCountRef}
        className="main-oval"
        placeholder="Quantity (max 50)"
        type="number"
      />
      {warnAboutEthereumMainnet && (
        <div>{`Please connect to Ethereum ${getCorrectNetName()}`}</div>
      )}
    </>
  );
};
